<template>
  <section class="property-additional-fee">
    <bg-card>
      <div class="section-title-with-button mb-16">
        <bg-text size="heading-5">Biaya Tambahan</bg-text>
        <bg-button
          variant="secondary"
          :disabled="isLoadingFees || loading"
          @click="handleAddAdditionalFee"
        >
          Tambah Biaya
        </bg-button>
      </div>

      <bg-divider class="mb-24" />

      <additional-fee-list
        :is-loading-fees="isLoadingFees || loading"
        :fee-list="additionalFees"
        :current-page="currentPage"
        :total-page="totalPage"
        @change-page="handleChangePage"
        @hapus-fee="handleDeleteGeneralLevel"
        @hapus-listing-price="handleDeleteListingLevel"
        @ubah-fee="handleEditGeneralLevel"
        @ubah-listing-price="handleEditListingLevel"
      />

      <additional-fee-modal
        :is-open.sync="isAdditionalFeeModalOpen"
        :form-data="additionalFeeFormData"
        :edit-data.sync="editModalData"
        :level="editModalLevel"
        @action-success-submit="handleUpdateFeeSuccess"
      />

      <delete-confirm-modal
        :is-open.sync="isDeleteModalOpen"
        :level="deleteModalLevel"
        :data="deleteModalData"
        @action-success-delete="handleUpdateFeeSuccess"
      />
    </bg-card>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { BgCard, BgText, BgDivider, BgButton } from 'bangul-vue';
import { endpoints } from '@admin_endpoints/additional-fee';
import AdditionalFeeList from './_components/AdditionalFeeList';

export default {
  name: 'PropertyAdditionalFee',

  components: {
    AdditionalFeeList,
    AdditionalFeeModal: () => import('./_components/AdditionalFeeModal'),
    DeleteConfirmModal: () => import('./_components/DeleteConfirmModal'),
    BgCard,
    BgText,
    BgDivider,
    BgButton,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isLoadingFees: false,
      additionalFees: [],
      currentPage: 1,
      totalPage: 0,
      params: {
        offset: 0,
        limit: 8,
      },
      isModalAdditionalCostOpen: false,
      selectedCost: {},
      isDeleteModalOpen: false,
      deleteModalLevel: null,
      deleteModalData: {},
      isAdditionalFeeModalOpen: false,
      editModalLevel: null,
      editModalData: null,
      additionalFeeFormData: {
        rooms: [],
        additional_fees: [],
        owner_commission_percentage: '',
      },
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail']),

    propertyId() {
      return this.$route.params.propertyId;
    },
  },

  created() {
    this.handleUpdateBreadcrumb();
    this.fetchAdditionalFeeList();
    this.fetchAdditionalFeeFormData();
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleUpdateBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/contract`,
        },
      });
    },

    async fetchAdditionalFeeList() {
      this.isLoadingFees = true;

      const {
        additional_fees,
        metadata,
      } = await endpoints.getPropertyAdditionalFeeList(
        this.propertyId,
        this.params
      );

      if (additional_fees) {
        this.additionalFees = additional_fees.map(item => ({
          ...item,
          expanded: false,
          show_action: false,
        }));
        this.totalPage = Math.ceil(metadata.total / this.params.limit);
      }

      this.isLoadingFees = false;
    },

    async fetchAdditionalFeeFormData() {
      this.isLoadingFees = true;

      const {
        additional_fees,
        rooms,
        contract,
      } = await endpoints.getPropertyAdditionalFeeFormData(this.propertyId);

      if (additional_fees && rooms && contract) {
        const ownerCommissionPercentage = 
          `${100 - parseInt(contract.basic_commission)}%`;

        this.additionalFeeFormData.additional_fees = additional_fees;
        this.additionalFeeFormData.rooms = rooms;
        this.additionalFeeFormData.owner_commission_percentage = 
          ownerCommissionPercentage;
      }

      this.isLoadingFees = false;
    },

    handleChangePage(offsetMultiplier = 0) {
      this.params.offset = this.params.limit * offsetMultiplier;
      this.currentPage = offsetMultiplier + 1;

      this.fetchAdditionalFeeList();
    },

    handleDeleteGeneralLevel(fee) {
      this.deleteModalLevel = 'general';
      this.isDeleteModalOpen = true;
      this.deleteModalData = fee;
    },

    handleDeleteListingLevel(room) {
      this.deleteModalLevel = 'listing';
      this.isDeleteModalOpen = true;
      this.deleteModalData = room;
    },

    handleAddAdditionalFee() {
      this.isAdditionalFeeModalOpen = true;
      this.editModalLevel = null;
      this.editModalData = null;
    },

    handleEditGeneralLevel(fee) {
      this.isAdditionalFeeModalOpen = true;
      this.editModalLevel = 'general';
      this.editModalData = fee;
    },

    handleEditListingLevel(item) {
      this.isAdditionalFeeModalOpen = true;
      this.editModalLevel = 'listing';
      this.editModalData = item;
    },

    handleUpdateFeeSuccess() {
      this.handleChangePage();
      this.fetchAdditionalFeeFormData();
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyAdditionalFee.scss"></style>
