<template>
  <section class="additional-fee">
    <bg-skeleton
      v-if="isLoadingFees"
      width="100%"
      height="50px"
      data-testid="additional-fee-loader"
    />
    <div v-else class="additional-fee-content">
      <table
        :class="['additional-fee-content__table', { empty: !feeList.length }]"
        data-testid="additional-fee-list-table"
      >
        <thead class="additional-fee-content__table-head">
          <tr>
            <th></th>
            <th><bg-text size="title-5">Nama Biaya</bg-text></th>
            <th>
              <bg-text size="title-5">Penyewa Bisa Pilih Mandiri</bg-text>
            </th>
            <th><bg-text size="title-5">Jenis Biaya</bg-text></th>
            <th>
              <bg-text size="title-5">Termasuk di Dalam Harga Sewa</bg-text>
            </th>
            <th><bg-text size="title-5">Tipe Pembayaran Biaya</bg-text></th>
            <th><bg-text size="title-5">Ketentuan Bagi Hasil</bg-text></th>
            <th><bg-text size="title-5">Action</bg-text></th>
          </tr>
        </thead>

        <template v-if="feeList.length">
          <tbody
            v-for="item in feeList"
            :key="`fee-${item.id}`"
            class="additional-fee-content__table-body"
          >
            <tr>
              <td>
                <bg-button
                  variant="tertiary"
                  size="sm"
                  :data-testid="`expand-fee-${item.id}`"
                  @click="item.expanded = !item.expanded"
                >
                  <bg-icon
                    name="chevron-down"
                    size="sm"
                    :class="{ expanded: item.expanded }"
                  />
                </bg-button>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <bg-label-rainbow
                  :color="item.is_chooseable_by_tenant ? 'green' : 'yellow'"
                >
                  {{ item.is_chooseable_by_tenant ? 'Ya' : 'Tidak' }}
                </bg-label-rainbow>
                <bg-text v-if="item.is_chooseable_by_tenant" size="body-4">
                  {{ tenantFunnelFormatter(item.funnel) }}
                </bg-text>
              </td>
              <td>
                <bg-label-rainbow
                  :color="item.fee_type === 'fixed' ? 'green' : 'yellow'"
                >
                  {{ item.fee_type === 'fixed' ? 'Wajib' : 'Opsional' }}
                </bg-label-rainbow>
              </td>
              <td>
                <bg-label-rainbow
                  :color="
                    item.is_fee_included_in_listing ? 'green' : 'yellow'
                  "
                >
                  {{
                    item.is_fee_included_in_listing ? 'Ya' : 'Tidak'
                  }}
                </bg-label-rainbow>
                <bg-text
                  v-if="item.is_fee_included_in_listing"
                  size="body-4"
                >
                  {{
                    feeVisibilityFormatter(
                      item.is_fee_name_appear, item.is_fee_amount_appear
                    )
                  }}
                </bg-text>
              </td>
              <td>
                <bg-label-rainbow
                  :color="item.payment_type === 'fixed' ? 'blue' : 'yellow'"
                >
                  {{ item.payment_type === 'fixed' ? 'Tetap' : 'Satu Kali' }}
                </bg-label-rainbow>
              </td>
              <td>
                <bg-label-rainbow>
                  {{
                    commissionTypeFormatter(
                      item.owner_commission_type,
                      item.owner_commission_percentage
                    )
                  }}
                </bg-label-rainbow>
              </td>
              <td>
                <bg-dropdown
                  v-model="item.show_action"
                  menu-size="fit-to-content"
                  menu-placement="bottom-end"
                  :close-on-select="false"
                  data-testid="additional-fee-action"
                >
                  <template #trigger>
                    <bg-icon name="more-vertical" />
                  </template>
                  <bg-dropdown-item
                    :data-testid="`ubah-fee-${item.id}`"
                    @click="$emit('ubah-fee', item)"
                  >
                    Ubah
                  </bg-dropdown-item>
                  <bg-dropdown-item
                    :data-testid="`hapus-fee-${item.id}`"
                    @click="$emit('hapus-fee', item)"
                  >
                    Hapus
                  </bg-dropdown-item>
                </bg-dropdown>
              </td>
            </tr>
            <tr
              :class="[
                'additional-fee-content__table-body-price',
                { 'is-open': item.expanded }
              ]"
              :data-testid="`listing-fee-${item.id}`"
            >
              <td colspan="2"></td>
              <td colspan="4">
                <bg-card class="mb-16">
                  <table class="additional-fee-content__table-body-price-table">
                    <thead>
                      <tr>
                        <th>
                          <bg-text size="body-1">Listing</bg-text>
                        </th>
                        <th>
                          <bg-text size="body-1">Harga</bg-text>
                        </th>
                        <th>
                          <bg-text size="body-1">Action</bg-text>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="room in item.rooms"
                        :key="`fee-room-${room.id}`"
                      >
                        <td>
                          <bg-text size="body-2">{{ room.name }}</bg-text>
                        </td>
                        <td>
                          <bg-label-rainbow
                            v-if="item.pricing_method === 'defined_by_billing'"
                            color="yellow"
                          >
                            Disesuaikan dengan Tagihan
                          </bg-label-rainbow>
                          <bg-text
                            v-else
                            v-for="(price, index) in room.amounts"
                            :key="`fee-price-${index}`"
                            size="body-2"
                          >
                            {{ additionalFeePriceFormatter(price) }}
                          </bg-text>
                        </td>
                        <td>
                          <div>
                            <bg-button-icon
                              icon="edit"
                              variant="tertiary-naked"
                              :data-testid="`ubah-listing-price-${room.id}`"
                              @click="handleEditListing(item, room)"
                            />
                            <bg-button-icon
                              icon="delete"
                              variant="tertiary-naked"
                              :data-testid="`hapus-listing-price-${room.id}`"
                              @click="handleDeleteListing(item, room)"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </bg-card>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-else>
          <tbody
            class="additional-fee-content__table-body--empty"
            data-testid="fee-empty-state"
          >
            <tr>
              <td colspan="8">
                <bg-text size="body-2" class="text-muted">
                  Tidak ada data yang ditampilkan
                </bg-text>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>

    <div class="additional-fee-pagination">
      <bg-skeleton v-if="isLoadingFees" width="30%" height="30px" />
      <template v-else>
        <div v-if="feeList.length > 0">
          <bg-pagination
            v-model="localCurrentPage"
            :page-total="totalPage"
            data-testid="fee-list-pagination"
            @click="handleChangePage"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { currencyFormatter } from 'Utils/formatter';

import {
  BgText,
  BgCard,
  BgIcon,
  BgLabelRainbow,
  BgDropdown,
  BgDropdownItem,
  BgButton,
  BgButtonIcon,
  BgSkeleton,
  BgPagination,
} from 'bangul-vue';

const TIME_FORMAT = {
  daily: 'Hari',
  weekly: 'Minggu',
  monthly: 'Bulan'
};
const FEE_FORMAT = {
  kg: 'kg',
  person: 'orang',
  item: 'item/pcs'
};

export default {
  name: 'AdditionalFeeList',

  components: {
    BgText,
    BgCard,
    BgIcon,
    BgLabelRainbow,
    BgDropdown,
    BgDropdownItem,
    BgButton,
    BgButtonIcon,
    BgSkeleton,
    BgPagination,
  },

  props: {
    feeList: {
      type: Array,
      default: /* istanbul ignore next */ () => []
    },
    isLoadingFees: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    localCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(newVal) {
        return newVal;
      },
    },
  },

  methods: {
    tenantFunnelFormatter(funnel) {
      const [firstFunnel, secondFunnel] = funnel;

      const formattedFirstFunnel =
        firstFunnel.charAt(0).toUpperCase() + firstFunnel.slice(1);
      const formattedSecondFunnel = secondFunnel ?
        `dan ${secondFunnel.charAt(0).toUpperCase() + secondFunnel.slice(1)}` : '';

      return `${formattedFirstFunnel} ${formattedSecondFunnel}`;
    },

    additionalFeePriceFormatter(roomFeePrice) {
      const { amount, time_unit, fee_unit} = roomFeePrice;

      const price = currencyFormatter(amount);
      const priceTimeUnit = TIME_FORMAT[time_unit];
      const priceFeeUnit = FEE_FORMAT[fee_unit];

      return `Rp${price} / ${priceTimeUnit || priceFeeUnit} ${ priceTimeUnit && priceFeeUnit ? `(Per ${priceFeeUnit})` : '' }`;
    },

    feeVisibilityFormatter(feeVisibility, amountVisibility) {
      if (feeVisibility && amountVisibility) {
        return 'Nama biaya dan harga ditampilkan di rincian sewa';
      } else if (!feeVisibility && !amountVisibility) {
        return 'Nama biaya dan harga tidak ditampilkan di rincian sewa';
      } 
      
      return `Hanya ${feeVisibility ? 'nama biaya' : 'harga'} yang ditampilkan di rincian sewa`;
    },

    commissionTypeFormatter(commissionType, percentage) {
      const percentageNumber = parseFloat(percentage);

      if (commissionType === 'basic_commission') {
        return 'Sesuai Basic Commission';
      } else if (commissionType === 'full_to_owner') {
        return 'Full ke Pemilik';
      } else if (percentageNumber >= 0 && percentageNumber < 100) {
        return `${percentageNumber}% ke Pemilik`;
      }

      return 'Full ke Mamikos';
    },

    handleChangePage(page) {
      const target = document.getElementById('main-header');
      target.scrollIntoView({ behavior: 'smooth' });

      this.$emit('change-page', page - 1);
    },

    handleEditListing(item, room) {
      this.$emit('ubah-listing-price', {
        ...item,
        selectedRoom: room,
      });
    },

    handleDeleteListing(item, room) {
      this.$emit('hapus-listing-price', {
        feeId: item.id,
        feeName: item.name,
        pricingMethod: item.pricing_method,
        ...room,
      });
    },
  },
};
</script>

<style lang="scss" src="./AdditionalFeeList.scss" scoped />
